import React from 'react';
import { useForm } from 'react-hook-form';
import '../styles/components/main.css';
import { emailRegex } from '../definitions';
import { useSearchParams, useNavigate } from 'react-router-dom';
import services from '../services/index';
import cx from 'classnames';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import InvalidURL from './InvalidURL';
import NoResponse from './NoResponse';

const Main = () => {
  const [token, setToken] = React.useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  /// Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = React.useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    try {
      console.log('inside set token');
      const captchaToken = await executeRecaptcha('mailchimp');

      setToken(captchaToken);
    } catch (e) {}
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  React.useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const [searchParams] = useSearchParams();
  const [docInfo, setDocInfo] = React.useState();
  const [errorType, setErrorType] = React.useState({
    documentIdWrong: false,
    serverNoResponse: false,
  });
  let navigate = useNavigate();

  const docId = searchParams.get('doc_id');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { name, country, company, email, message, consent } = watch();

  const onSubmit = async () => {
    handleReCaptchaVerify();

    try {
      if (!token) throw new Error('token is empty');

      const payload = {
        doc_id: docId,
        contact_name: name,
        country: country,
        company: company,
        email: email,
        consent: consent,
        message: message,
        token: token,
      };
      const res = await services.formServices.postForm(payload);
      if (res?.data.statusCode === 200) {
        navigate('/success', { state: { title: docInfo.title, show: true } });
      }
    } catch (e) {
      navigate('/fail', { state: { title: docInfo.title, show: true } });
    }
  };

  React.useEffect(() => {
    const getFormInfo = async () => {
      try {
        const resInfo = await services.formServices.getDocInfo(docId);
        if (resInfo.statusCode === 200) {
          setDocInfo(resInfo);
        }
      } catch (error) {
        if (error instanceof TypeError) {
          setErrorType({ serverNoResponse: true });
        } else {
          setErrorType({ documentIdWrong: true });
        }
      }
    };

    getFormInfo();
  }, [docId]);

  return (
    <>
      <div className="outer-container">
        {docInfo ? (
          <>
            <div className="mb-16 cover">
              <div className="my-6 font-medium text-3xl sm:text-5xl form-alignment title-width text-white">
                {docInfo.title}
              </div>
            </div>
            <main className="mb-24">
              <div className="mb-10 rounded-md form-alignment image-container">
                <img src={docInfo.preview_url} alt="document preview" className="preview-image form-alignment" />
              </div>

              <div className="form-alignment form-width font-medium text-xl mb-8 leading-6">
                To obtain the full version of {docInfo.file_name}, please fill out this form:
              </div>

              <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col form-alignment">
                <label htmlFor="name" className="font-semibold text-xl text-gray-600 mb-6">
                  <span className="block mb-2">Contact Name</span>
                  <input
                    className={cx('pl-6 rounded-none h-12 mb-1 form-width text-lg font-medium', {
                      'border-gray-500': !errors.name,
                      'border-red-500': errors.name,
                    })}
                    type="text"
                    id="name"
                    placeholder="Your Name"
                    {...register('name', { required: true })}
                  ></input>
                  {errors.name && (
                    <div className="font-medium pt-1 text-red-500 text-right form-width">Please enter this filed</div>
                  )}
                </label>
                <label htmlFor="email" className="font-semibold text-xl mb-6 text-gray-600">
                  <span className="block mb-2">Email Address</span>
                  <input
                    className={cx('border-gray-500 pl-6 rounded-none h-12 mb-1 form-width text-lg font-medium', {
                      'border-gray-500': !errors.email,
                      'border-red-500': errors.email,
                    })}
                    type="text"
                    id="email"
                    placeholder="Your Email Address"
                    {...register('email', { required: true, pattern: emailRegex })}
                  ></input>
                  {errors.email && (
                    <div className="font-medium pt-1 text-red-500 text-right form-width">
                      Please enter a valid email
                    </div>
                  )}
                </label>
                <label htmlFor="company" className="font-semibold text-xl mb-6 text-gray-600">
                  <span className="block mb-2">Company Name</span>
                  <input
                    className={cx('pl-6 rounded-none h-12 mb-1 form-width text-lg font-medium', {
                      'border-gray-500': !errors.company,
                      'border-red-500': errors.company,
                    })}
                    type="text"
                    id="company"
                    placeholder="Your Company"
                    {...register('company', { required: true })}
                  ></input>
                  {errors.company && (
                    <div className="font-medium pt-1 text-red-500 text-right form-width">Please enter this filed</div>
                  )}
                </label>
                <label htmlFor="country" className="font-semibold text-xl mb-6 text-gray-600">
                  <span className="block mb-2">Country</span>
                  <input
                    className={cx('pl-6 rounded-none h-12 mb-1 form-width text-lg font-medium', {
                      'border-gray-500': !errors.country,
                      'border-red-500': errors.country,
                    })}
                    type="text"
                    id="country"
                    placeholder="Your Country"
                    {...register('country', { required: true })}
                  ></input>
                  {errors.country && (
                    <div className="font-medium pt-1 text-red-500 text-right form-width">Please enter this filed</div>
                  )}
                </label>

                <label htmlFor="message" className="font-semibold text-xl mb-2 text-gray-600">
                  Message to Us <span className="text-gray-500 font-medium">(Optional)</span>
                </label>
                <textarea
                  className="border-gray-500 border pl-6 pt-2 leading-snug rounded-none mb-6 form-width row text-lg font-medium resize-none"
                  id="message"
                  rows="8"
                  cols="1"
                  placeholder="Your Message"
                  {...register('message')}
                ></textarea>
                <label htmlFor="consent" className="form-width check-box-label text-xl">
                  <span className="check-box-text">
                    I consent to receive promotional communication (which may include phone, email and social) from
                    Peplink. I understand I may proactively opt-out of the communications with Peplink at any time.
                  </span>
                  <input type="checkbox" id="consent" {...register('consent')}></input>
                  <span className="check-box"></span>
                </label>
                <div className="form-width font-medium text-xl mb-24 leading-6">
                  This site is protected by reCAPTCHA and the Google{' '}
                  <a className="text-primary font-medium" href="https://policies.google.com/privacy">
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a className="text-primary font-medium" href="https://policies.google.com/terms">
                    Terms of Service
                  </a>{' '}
                  apply.
                </div>
                <div className="form-width flex justify-end">
                  <input
                    className="submit-button text-lg font-medium border-none rounded-none bg-primary text-white"
                    type="submit"
                    value="SUBMIT"
                  ></input>
                </div>
              </form>
            </main>
          </>
        ) : errorType.documentIdWrong ? (
          <InvalidURL />
        ) : errorType.serverNoResponse ? (
          <NoResponse />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const ReCaptChaComponent = () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
    <Main />
  </GoogleReCaptchaProvider>
);

export default ReCaptChaComponent;
