import Data from './data.json';
import DesktopHeader from './DesktopHeader';
import React from 'react';
import Search from './components/Search';
import TabletHeader from './TabletHeader/TabletHeader';
import TabletSearch from './TabletHeader/TabletSearch';

export default () => {
  const [showSearch, setShowSearch] = React.useState(false);
  const [showBlur, setShowBlur] = React.useState(false);
  const [showMobileDetail, setShowMobileDetail] = React.useState('');
  const [blackHeaderBackground, setBlackHeaderBackground] = React.useState(false);
  const [currentTopic, setCurrentTopic] = React.useState('');
  const [tabletState, setTabletState] = React.useState({
    navWrapper: false,
    closeBtn: false,
    mainMenus: false,
    tabletSearch: false,
  });

  function reset() {
    setShowSearch(false);
    setShowBlur(false);
    setShowMobileDetail('');
    setBlackHeaderBackground(false);
    setTabletState({
      navWrapper: false,
      closeBtn: false,
      mainMenus: false,
      tabletSearch: false,
    });
  }

  return (
    <>
      <header id="header" className="header">
        {/* tab */}
        <div className={`nav-wrapper ${tabletState.navWrapper ? 'active' : ''}`}>
          <div className="mobile-site-logo ">
            <a
              className={`mobile-site-logo-a ${showMobileDetail === '' ? 'active' : ''}`}
              href="https://www.peplink.com/"
            >
              <img src="/assets/menu-tab-logo.svg" alt="Menu Tab Logo" />
            </a>
            <div
              class={`mobile-menu-back ${showMobileDetail !== '' ? 'active' : ''}`}
              onClick={() => setShowMobileDetail('')}
            >
              <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 1L2 7L8 13" stroke="white" stroke-width="2" stroke-linecap="round"></path>
              </svg>
            </div>
          </div>
          <div className="mobile-nav-btns">
            <div
              className="mobile-nav-search"
              onClick={() => {
                setTabletState({ navWrapper: true, closeBtn: true, mainMenus: false, tabletSearch: true });
                setShowBlur(true);
              }}
            ></div>
            <button id="mobile-open-menu ">
              {tabletState.closeBtn ? (
                <div class={`mobile-close-menu-icon `} onClick={() => reset()}>
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line
                      x1="1.41421"
                      y1="1"
                      x2="11.3137"
                      y2="10.8995"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></line>
                    <line
                      x1="1.65686"
                      y1="10.8993"
                      x2="11.5564"
                      y2="0.999768"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></line>
                  </svg>
                </div>
              ) : (
                <div
                  class={`mobile-open-menu-icon`}
                  onClick={() => {
                    setTabletState({ navWrapper: true, closeBtn: true, mainMenus: true, tabletSearch: false });
                    setShowBlur(true);
                  }}
                >
                  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="1" y1="1" x2="15" y2="1" stroke="white" stroke-width="2" stroke-linecap="round"></line>
                    <line x1="1" y1="11" x2="15" y2="11" stroke="white" stroke-width="2" stroke-linecap="round"></line>
                  </svg>
                </div>
              )}
            </button>
          </div>
        </div>
        {/* desktop */}
        <div className="nav-content">
          <div className="main-menu">
            {/* <div className="main-menu fixed-on-desktop"> */}
            <div className={`desktop-main-menu`}>
              <div className={`menu-blur-layer `}></div>
              <div
                className="main-menu-inner-wrapper"
                style={{ backgroundColor: blackHeaderBackground ? '#000000' : '' }}
                onMouseLeave={() => {
                  setShowSearch(false);
                  setShowBlur(false);
                }}
                onMouseEnter={() => setShowBlur(true)}
              >
                <div className="site-logo-desktop">
                  <a href="https://www.peplink.com/">
                    <div className="menu-logo"></div>
                  </a>
                </div>
                <div className="main-menu-nav-container ">
                  <ul
                    className="icon-display-false header-ul"
                    onMouseEnter={() => {
                      setShowSearch(false);
                      setBlackHeaderBackground(true);
                    }}
                    onMouseLeave={() => setBlackHeaderBackground(false)}
                  >
                    {Data.map((data, index) => (
                      <DesktopHeader
                        key={index}
                        data={data}
                        name={data.name}
                        Data={Data}
                        blackHeaderBackground={blackHeaderBackground}
                        setCurrentTopic={setCurrentTopic}
                        currentTopic={currentTopic}
                      />
                    ))}
                  </ul>
                </div>
                <div className="desktop-search">
                  <input
                    className="search-icon"
                    value=""
                    onClick={() => {
                      setShowSearch(!showSearch);
                      setBlackHeaderBackground(!blackHeaderBackground);
                    }}
                    style={{ caretColor: 'transparent' }}
                  />
                  <Search
                    showSearch={showSearch}
                    blackHeaderBackground={blackHeaderBackground}
                    setBlackHeaderBackground={setBlackHeaderBackground}
                  />
                </div>
              </div>
            </div>
            {tabletState.mainMenus && (
              <div className={`mobile-main-menu ${tabletState.mainMenus ? 'active' : ''}`}>
                <TabletHeader
                  Data={Data}
                  showMobileDetail={showMobileDetail}
                  setShowMobileDetail={setShowMobileDetail}
                  menusState={tabletState.mainMenus}
                />
              </div>
            )}
            <div className={`mobile-main-menu-search ${tabletState.tabletSearch ? 'active' : ''}`}>
              <div className="mobile-search">
                <input className="search-icon" value="" type="submit" />
                <TabletSearch tabletSearch={tabletState.tabletSearch} />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class={`blur ${showBlur ? 'active' : ''}`} onClick={() => reset()}></div>
    </>
  );
};
