import React from 'react';
import TabletBuy from './TabletBuy';
import TabletCompany from './TabletCompany';
import TabletProduct from './TabletProduct';
import TabletSolutions from './TabletSolutions';
import TabletSupport from './TabletSupport';
import TabletTechnologies from './TabletTechnologies';

export default ({ Data, showMobileDetail, setShowMobileDetail, menusState }) => {
  return (
    menusState && (
      <>
        {showMobileDetail === '' && (
          <div class={`mobile-main-menu-title ${menusState ? 'active' : ''}`}>
            {Data.map((data) => (
              <div
                key={data.name}
                class={`mobile-menu-title mobile-menu-${data.name}`}
                onClick={() => setShowMobileDetail(data.name)}
              >
                {data.name}
              </div>
            ))}
          </div>
        )}

        <div class={`mobile-submenu ${showMobileDetail !== '' ? 'active' : ''}`}>
          {Data.filter((item) => item.name === showMobileDetail).map((data) => (
            <div
              key={data.name}
              class={`mobile-submenu-${data.name} mobile-submenu-content ${
                data.name === 'Solutions' || data.name === 'Support' ? 'double-col' : ''
              }  active`}
            >
              {showMobileDetail !== '' && data.name === 'Products' && <TabletProduct data={data} />}
              {showMobileDetail !== '' && data.name === 'Solutions' && <TabletSolutions data={data} />}
              {showMobileDetail !== '' && data.name === 'Technology' && <TabletTechnologies data={data} />}
              {showMobileDetail !== '' && data.name === 'Support' && <TabletSupport data={data} />}
              {showMobileDetail !== '' && data.name === 'Buy' && <TabletBuy data={data} />}
              {showMobileDetail !== '' && data.name === 'Company' && <TabletCompany data={data} />}
            </div>
          ))}
        </div>
      </>
    )
  );
};
