export default class AuthServices {
  constructor(props) {
    this.url = '';
    this.http = props.http;
  }

  login = () => {
    return new Promise((resolve, reject) => {
      this.http
        .post('/authenticate-form', {
          emailAddress: process.env.REACT_APP_EMAIL,
          password: process.env.REACT_APP_PASSWORD,
        })
        .then((res) => {
          localStorage.setItem(`${process.env.REACT_APP_APP_NAME}_TOKEN`, res.id_token);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };
}
