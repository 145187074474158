import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/components/main.css';

const Success = () => {
  let location = useLocation();
  let navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (location.state == null) {
      navigate('/');
    }
  }, [location.state, navigate]);

  return (
    <>
      <div className="outer-container">
        <div className="mb-16 sm:mb-40 cover">
          {location.state && (
            <div className="my-6 font-medium text-3xl sm:text-5xl form-alignment text-white">
              {location.state.title}
            </div>
          )}
        </div>
        <div className="form-alignment mb-40">
          {location.state.show ? (
            <>
              <div className="text-2xl sm:text-4xl font-medium mb-4">Thank you!</div>
              <div className="sm:flex sm:items-start">
                <div>
                  <div className="mb-2 text-lg sm:text-2xl font-medium">
                    You will receive an email with a link* to your requested information shortly.
                  </div>
                  <div className="mb-4 text-lg sm:text-2xl font-medium text-gray-600">
                    * This link is only valid for 24 hours
                  </div>
                </div>
                <img className="envelop sm:ml-64 mb-4 sm:mb-0" src="assets/envelop.svg" alt="envelop" />
              </div>
            </>
          ) : (
            <>
              <div className="text-2xl sm:text-4xl font-medium mb-4">You submission has been received</div>
              <div className="sm:flex sm:items-start">
                <div>
                  <div className="mb-2 text-lg sm:text-2xl font-medium leading-relaxed ">
                    Successful claimants will receive a PrimeCare order confirmation within 3 working days. If you do
                    not receive a response and believe you are eligible, please contact{' '}
                    <span className="text-primary font-medium underline">
                      <a href="mailto:cs@peplink.com" target="_blank" rel="noopener noreferrer">
                        cs@peplink.com
                      </a>
                    </span>
                    .
                  </div>
                </div>
                <img className="envelop sm:ml-64 mb-4 sm:mb-0" src="assets/form-success.svg" alt="envelop" />
              </div>
            </>
          )}

          <button className="w-32 h-16 text-xl font-medium border-none rounded-none bg-primary text-white flex justify-center items-center">
            <a href="https://www.peplink.com">HOME</a>
          </button>
        </div>
      </div>
    </>
  );
};

export default Success;
