// import axios from 'axios';

export default class FormServices {
  constructor(props) {
    this.http = props.http;
  }

  getDocInfo = (id) => {
    return this.http.getDocInfo(`/docs/${id}`, {});
  };

  postForm = (payload) => {
    return this.http.postForm('/dl-recs', payload);
  };

  postFeedback = (payload) => {
    return this.http.postFeedback('/forms', payload);
  };
}
