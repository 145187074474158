import React from 'react';
import '../styles/components/main.css';

const NoResponse = () => {
  return (
    <div className="form-alignment mt-32 sm:mt-64 mb-48">
      <div className="text-2xl sm:text-4xl font-medium mb-8">Temporarily unavailable!</div>
      <div className="mb-40 text-lg sm:text-2xl font-medium">
        We’re sorry, but the requested file is temporarily unavailable. Please try again later.{' '}
      </div>
      <button className="w-32 h-16 text-xl font-medium border-none rounded-none bg-primary text-white flex justify-center items-center">
        <a href="https://www.peplink.com">HOME</a>
      </button>
    </div>
  );
};

export default NoResponse;
