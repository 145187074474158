import React from 'react';

export default ({ data }) => {
  return data.map((item) =>
    item.title ? (
      <div className="menu-section">
        {item.link ? (
          <a href={item.link}>
            <p>{item.title}</p>
          </a>
        ) : (
          <p>{item.title}</p>
        )}
        <div className="menu-item">
          <div class="single-item ">
            {item.items.map((ele) => (
              <a key={ele.text} href={ele.link}>
                {ele.text}
              </a>
            ))}
          </div>
          <div class="single-item remaining-items "></div>
        </div>
      </div>
    ) : (
      <div className="menu-section">
        {item.link ? (
          <a href={item.link}>
            <p></p>
          </a>
        ) : (
          <p></p>
        )}
        <div className="menu-item">
          {item.items.map((ele) => (
            <div key={ele.text} class="big-item">
              <a href={ele.link}>{ele.text} </a>
            </div>
          ))}
        </div>
      </div>
    )
  );
};
