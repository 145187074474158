import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/components/main.css';

const Fail = () => {
  let location = useLocation();
  let navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (location.state == null) {
      navigate('/');
    }
  }, [location.state, navigate]);

  return (
    <>
      <div className="outer-container">
        <div className="mb-16 sm:mb-40 cover">
          {location.state && (
            <div className="my-6 font-medium text-3xl sm:text-5xl form-alignment text-white">
              {location.state.title}
            </div>
          )}
        </div>
        <div className="form-alignment mb-48">
          <div className="text-2xl sm:text-4xl font-medium mb-8">Error!</div>
          <div className="mb-40 text-lg sm:text-2xl font-medium">
            We’re sorry, but something went wrong. Please try again.{' '}
          </div>
          <button className="w-32 h-16 text-xl font-medium border-none rounded-none bg-primary text-white flex justify-center items-center">
            <a href="https://www.peplink.com">HOME</a>
          </button>
        </div>
      </div>
    </>
  );
};

export default Fail;
