import Buy from './submenu/Buy';
import Company from './submenu/Company';
import Products from './submenu/Products';
import React from 'react';
import Solutions from './submenu/Solutions';
import Support from './submenu/Support';
import Technologies from './submenu/Technologies';

export default ({ name, data, Data, blackHeaderBackground }) => {
  const techData = data.contents.filter((item) => item.title === 'TECHNOLOGIES');
  const supportData = data.contents.filter((item) => item.type === 'big-item' || item.title === 'SUPPORT');
  const companyData = data.contents.filter((item) => item.title === 'COMPANY');
  const buyData = data.contents.filter((item) => item.title === 'HARDWARE' || item.title === 'SOFTWARE AND SERVICES');
  const solutionData = Data.filter((item) => item.name === 'Solutions');
  const productData = Data.filter((item) => item.name === 'Products');

  return (
    <ul className={`icon-display-false submenu-bg`} style={{ backgroundColor: blackHeaderBackground ? '#000000' : '' }}>
      {(name === 'Technology' && <Technologies data={techData} />) ||
        (name === 'Support' && <Support data={supportData} />) ||
        (name === 'Company' && <Company data={companyData} />) ||
        (name === 'Buy' && <Buy data={buyData} />) ||
        (name === 'Solutions' && <Solutions data={solutionData} />) ||
        (name === 'Products' && <Products data={productData} />)}
    </ul>
  );
};
