import React from 'react';

export default ({ showSearch, blackHeaderBackground, setBlackHeaderBackground }) => {
  const [searchInput, setSearchInput] = React.useState('');
  let inputRef = React.useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    window.location.href = `https://peplink.com/?s=${searchInput}`;
  };

  React.useEffect(() => {
    let timerId;
    if (showSearch && inputRef.current) {
      timerId = setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }

    return () => {
      setSearchInput('');
      clearTimeout(timerId);
    };
  }, [showSearch]);

  return (
    <div
      className={`search-dropdown ${showSearch ? 'active' : ''}`}
      style={{ backgroundColor: blackHeaderBackground ? '#000000' : '' }}
      onMouseLeave={() => setBlackHeaderBackground(false)}
    >
      <p>SEARCH</p>
      <div className="search-bar">
        <form id="desktop-search" method="GET" action="https://peplink.com" onSubmit={handleSubmit}>
          <input className="search-icon-input" type="submit" value="" />
          <label className="visually-hidden" aria-hidden="true" htmlFor="site_search">
            Search
          </label>
          <input
            id="site_search"
            type="text"
            name="s"
            placeholder="Search Peplink.com"
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
            autoComplete="off"
            ref={inputRef}
          />
        </form>
      </div>
    </div>
  );
};
