export const SERIES = {
  BALANCE: {
    key: 'balance',
    text: 'Balance',
  },
  MAX: {
    key: 'max',
    text: 'MAX',
  },
};

export const DEFAULT_PRODUCTS = {
  balance: {
    product1: 'BPL-021',
    product2: 'BPL-021X-LTE-US-T-PRM',
    product3: 'BPL-031-LTE-US-T',
  },
  max: {
    product1: 'MAX-BR1-MINI-LTE-US-T',
    product2: 'MAX-BR1-MK2-LTEA-W-T',
    product3: 'MAX-BR1-PRO-LTEA-W-T',
  },
};

export const HIDDEN = ['SpeedFusion Connect (eSIM/FusionSIM)'];

export const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const MAIN_WEBSITE_URL = 'https://www.peplink.com';
export const HEADER_LOGO_URL = '//www.peplink.com/wp-content/uploads/2020/04/peplink_logo.svg';
export const HEADER_LOGO_SRCSET = [
  '(min-width:0px) and (max-width:544px)',
  '(min-width:544px) and (max-width:768px)',
  '(min-width:768px) and (max-width:992px)',
  '(min-width:992px) and (max-width:1200px)',
  '(min-width:1200px)',
];
