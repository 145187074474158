import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Main from './components/Main';
import Complimentary from './components/Complimentary';
import Success from './components/Success';
import Fail from './components/Fail';
import React from 'react';
import NoMatch from './components/NoMatch';
import Expired from './components/Expired';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="docs" element={<Main />} />
        <Route path="success" element={<Success />} />
        <Route path="fail" element={<Fail />} />
        <Route path="expired" element={<Expired />} />
        <Route path="*" element={<NoMatch />} />
        <Route path="complimentary" element={<Complimentary />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
