import React from 'react';
import '../styles/components/main.css';
import { useNavigate, useSearchParams } from 'react-router-dom';

const Expired = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const docId = searchParams.get('doc_id');

  const handleOnClick = () => {
    navigate(`/docs/?doc_id=${docId}`);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (docId === '' || docId === null) {
      navigate('/');
    }
  }, [docId, navigate]);

  return (
    <>
      <div className="outer-container">
        <div className="form-alignment mt-32 sm:mt-64 mb-48 ">
          <div className="text-2xl sm:text-4xl font-medium mb-8">Expired URL!</div>
          <div className="mb-40 text-lg sm:text-2xl font-medium">
            The URL has expired. Please submit the form again to obtain a new download link.{' '}
          </div>
          <button
            className="w-auto px-4 h-16 text-xl font-medium border-none rounded-none bg-primary text-white flex justify-center items-center"
            onClick={handleOnClick}
          >
            FILE DOWNLOAD FORM
          </button>
        </div>
      </div>
    </>
  );
};

export default Expired;
