import React from 'react';

export default ({ data }) => {
  return (
    <div class="col">
      {data.map((item) => (
        <div key={item.id} class="footer-menu-section">
          <a href={item.link}>
            <span>{item.title}</span>
          </a>
          <ul>
            {item.item.map((element) => (
              <div key={element.id}>
                <a href={element.link}>{element.text}</a>
              </div>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
