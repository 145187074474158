import React from 'react';
// import { useForm } from 'react-hook-form';
import '../styles/components/main.css';
// import { emailRegex } from '../definitions';
// import { useSearchParams, useNavigate } from 'react-router-dom';
// import services from '../services/index';
// import cx from 'classnames';
// import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// import InvalidURL from './InvalidURL';
// import NoResponse from './NoResponse';

const Form = () => {
  // const [token, setToken] = React.useState(null);
  // const [errorSN, setErrorSN] = React.useState('');

  // const { executeRecaptcha } = useGoogleReCaptcha();

  /// Create an event handler so you can call the verification on button click event or form submit
  // const handleReCaptchaVerify = React.useCallback(async () => {
  //   if (!executeRecaptcha) {
  //     return;
  //   }

  //   try {
  //     const token = await executeRecaptcha('mailchimp');
  //     // const token = undefined;
  //     setToken(token);
  //   } catch {
  //     //
  //   }
  // }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  // React.useEffect(() => {
  //   handleReCaptchaVerify();
  // }, [handleReCaptchaVerify]);

  // let navigate = useNavigate();

  // const {
  //   register,
  //   handleSubmit,
  //   watch,
  //   setValue,
  //   formState: { errors },
  // } = useForm({
  //   criteriaMode: 'all',
  // });

  // let { name, sn, company, email, message } = watch();

  // const regex = /[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}/;

  // const checkSN = async (toSetValue = false) => {
  //   const temp_sn = sn.toUpperCase().replaceAll(',', '\n');
  //   let sn_arr = temp_sn.split('\n');
  //   let ok_flag = true;
  //   const new_arr = sn_arr
  //     .map((sn) => {
  //       sn = sn.trim();
  //       if (sn.length === 12) {
  //         sn = sn.slice(0, 4) + '-' + sn.slice(4, 8) + '-' + sn.slice(8, 12);
  //       }
  //       if (sn.length === 14 && regex.test(sn)) {
  //         return sn;
  //       } else {
  //         ok_flag = false;
  //         return null;
  //       }
  //     })
  //     .filter((sn) => sn !== null);

  //   const error_arr = sn_arr
  //     .map((sn) => {
  //       sn = sn.trim();
  //       if (sn.length === 12) {
  //         sn = sn.slice(0, 4) + '-' + sn.slice(4, 8) + '-' + sn.slice(8, 12);
  //       }
  //       if (sn.length === 14 && regex.test(sn)) {
  //         return null;
  //       } else {
  //         return sn;
  //       }
  //     })
  //     .filter((sn) => sn !== null);
  //   setErrorSN(error_arr.join(',   '));

  //   let sn_str = new_arr.join(', ');

  //   if (ok_flag && !errors.email && !errors.name) {
  //     if (toSetValue === true) {
  //       setValue('sn', sn_str);
  //     }
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // complimentary form submit
  // const onSubmit = async () => {
  //   handleReCaptchaVerify();
  //   checkSN(true);

  //   try {
  //     if (!token) throw new Error('token is empty');

  //     const temp_sn = sn.toUpperCase().replaceAll(',', '\n');
  //     let sn_arr = temp_sn.split('\n');

  //     const new_arr = sn_arr
  //       .map((sn) => {
  //         sn = sn.trim();
  //         if (sn.length === 12) {
  //           sn = sn.slice(0, 4) + '-' + sn.slice(4, 8) + '-' + sn.slice(8, 12);
  //         }
  //         if (sn.length === 14 && regex.test(sn)) {
  //           return sn;
  //         } else {
  //           return null;
  //         }
  //       })
  //       .filter((sn) => sn !== null);
  //     let sn_str = new_arr.join(', ');

  //     const payload = {
  //       contact_name: name,
  //       sn: sn_str,
  //       company: company,
  //       email: email,
  //       message: message,
  //       token: token,
  //     };
  //     const res = await services.formServices.postFeedback(payload);
  //     if (res?.status === 200) {
  //       navigate('/success', {
  //         state: { title: 'Additional PrimeCare Subscription for Eligible Customers', show: false },
  //       });
  //     }
  //   } catch (e) {
  //     navigate('/fail', { state: { title: 'Additional PrimeCare Subscription for Eligible Customers', show: false } });
  //   }
  // };

  React.useEffect(() => {
    // page redirect
    if (window.location.pathname === '/complimentary') {
      window.location.href = 'https://www.peplink.com';
    }
  }, []);

  return (
    <>
      <div className="outer-container">
        {/* complimentary page  */}
        {/* <div className="mb-16 cover">
          <div className="my-6 font-medium text-3xl sm:text-5xl form-alignment title-width text-white">
            Additional PrimeCare Subscription for Eligible Customers
          </div>
        </div> */}
        {/* <main className="mb-24">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col form-alignment">
            <div className="form-alignment form-width font-medium text-xl mb-8 leading-6">
              In response to a pricing reduction, of the BR1 Pro 5G/CAT20 on the 28th July 2022. Peplink will offer a
              complimentary 4-year PrimeCare plan to all units purchased up to 60 days prior to the announcement (28th
              July 2022).
              <br />
              <br />
              To proceed, please provide a few essential details including all the fields marked*.
            </div>

            <label htmlFor="name" className="font-semibold text-xl text-gray-600 mb-6">
              <span className="block mb-2">Contact Name *</span>
              <input
                className={cx('pl-6 rounded-none h-12 mb-1 form-width text-lg font-medium', {
                  'border-gray-500': !errors.name,
                  'border-red-500': errors.name,
                })}
                type="text"
                id="name"
                placeholder="Your Name"
                {...register('name', { required: true })}
              ></input>
              {errors.name && (
                <div className="font-medium pt-1 text-red-500 text-right form-width">Please enter this filed</div>
              )}
            </label>
            <label htmlFor="company" className="font-semibold text-xl mb-6 text-gray-600">
              <span className="block mb-2">
                Company Name<span className="text-gray-500 font-medium"> (if applicable)</span>
              </span>

              <input
                className={cx('pl-6 rounded-none h-12 mb-1 form-width text-lg font-medium', {
                  'border-gray-500': !errors.company,
                  'border-red-500': errors.company,
                })}
                type="text"
                id="company"
                placeholder="Your Company"
                {...register('company')}
              ></input>
              {errors.company && (
                <div className="font-medium pt-1 text-red-500 text-right form-width">Please enter this filed</div>
              )}
            </label>
            <label htmlFor="email" className="font-semibold text-xl mb-6 text-gray-600">
              <span className="block mb-2">Email Address *</span>
              <input
                className={cx('border-gray-500 pl-6 rounded-none h-12 mb-1 form-width text-lg font-medium', {
                  'border-gray-500': !errors.email,
                  'border-red-500': errors.email,
                })}
                type="text"
                id="email"
                placeholder="Your Email Address"
                {...register('email', { required: true, pattern: emailRegex })}
              ></input>
              {errors.email && (
                <div className="font-medium pt-1 text-red-500 text-right form-width">Invalid Email Address</div>
              )}
            </label>

            <label htmlFor="sn" className="font-semibold text-xl mb-6 text-gray-600">
              <span className="block mb-2">{`Device Serial Number(s) *`}</span>
              <textarea
                className={cx(
                  'border-gray-500 border pl-6 pt-2 leading-snug rounded-none mb-1 form-width row text-lg font-medium resize-none',
                  {
                    'border-gray-500': !errors.sn,
                    'border-red-500': (errors.sn && sn === '') || (errors.sn && sn !== '' && errorSN),
                  }
                )}
                id="sn"
                rows="8"
                cols="1"
                placeholder="0000-0000-0000"
                {...register('sn', { required: true, validate: checkSN })}
              ></textarea>
              {errors.sn && sn === '' && (
                <div className="font-medium pt-1 text-red-500 text-right form-width">Required field</div>
              )}
              {errors.sn && sn !== '' && errorSN && (
                <div className="font-medium pt-1 text-red-500 text-right form-width">Invalid SN:{errorSN}</div>
              )}
            </label>

            <label htmlFor="message" className="font-semibold text-xl mb-2 text-gray-600">
              Message<span className="text-gray-500 font-medium"> (Optional)</span>
            </label>
            <textarea
              className="border-gray-500 border pl-6 pt-2 leading-snug rounded-none mb-24 form-width row text-lg font-medium resize-none"
              id="message"
              rows="8"
              cols="1"
              placeholder="Your Message"
              {...register('message')}
            ></textarea>
            <div className="form-width font-medium text-xl mb-24 leading-6">
              By clicking SUBMIT you are agreeing to Peplink's{' '}
              <a className="text-primary font-medium underline" href="https://www.peplink.com/company/privacy">
                Privacy Policy
              </a>{' '}
              and subscription to Peplink's mailing list.
              <br />
              <br />
              This site is protected by reCAPTCHA and the Google{' '}
              <a className="text-primary font-medium underline" href="https://policies.google.com/privacy">
                Privacy Policy
              </a>{' '}
              and{' '}
              <a className="text-primary font-medium underline" href="https://policies.google.com/terms">
                Terms of Service
              </a>{' '}
              apply.
            </div>
            <div className="form-width flex justify-end">
              <input
                className="submit-button text-lg font-medium border-none rounded-none bg-primary text-white"
                type="submit"
                value="SUBMIT"
              ></input>
            </div>
          </form>
        </main> */}
        {/* <InvalidURL /> */}
      </div>
    </>
  );
};

const ReCaptChaComponent = () => (
  // <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
  <Form />
  // </GoogleReCaptchaProvider>
);

export default ReCaptChaComponent;
