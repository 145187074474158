import React from 'react';

export default ({ data }) => {
  const firstColData = data.contents[0].items.slice(0, 4);
  const restData = data.contents[0].items.slice(4);

  return (
    <>
      {data.contents.map((item, index) => (
        <div key={index} class="mobile-submenu-col">
          <div class="mobile-submenu-title">{item.title}</div>
          {item.type === 'subitem' ? (
            <>
              <div class="mobile-submenu-items double-item ">
                <div class="subitem  ">
                  {firstColData.map((element) => (
                    <>
                      <a key={element.item} href={element.link}>
                        <div class="sub-title">{element.item}</div>
                      </a>
                      <div class="sub">
                        {element.subitem.map((el, index) => (
                          <>
                            <div class="sub-title-item">
                              <a className={`${index === 0 ? 'first-link' : ''}`} href={el.link}>
                                {el.text}
                              </a>
                            </div>
                            {index !== element.subitem.length - 1 && <>|</>}
                          </>
                        ))}
                      </div>
                    </>
                  ))}
                </div>
                <div class="subitem remaining-items ">
                  {restData.map((element) => (
                    <a key={element.item} href={element.link}>
                      <div class="sub-title">{element.item}</div>
                    </a>
                  ))}
                  <div class="sub"></div>
                </div>
              </div>

              <div class="products-single-items">
                {item.singleItem.map((element) => (
                  <li key={element.text}>
                    <a href={element.link}>{element.text}</a>
                  </li>
                ))}
              </div>
            </>
          ) : (
            <div class="mobile-submenu-items  services-gap">
              <div class="single-item ">
                {item.items.map((element) => (
                  <a key={element.text} href={element.link}>
                    {element.text}
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};
