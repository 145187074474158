import axios from 'axios';
import services from './index';

const APP_NAME = process.env.REACT_APP_APP_NAME;

const headers = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const responseParser = (res) => res.data;
const errorParser = (res) => {
  console.error(res);
  return res.response;
};

export default class Http {
  constructor() {
    this.axiosInstance = axios.create({
      // eslint-disable-next-line no-undef
      baseURL: process.env.REACT_APP_FORM_SERVER,
      // baseURL: process.env.REACT_APP_API_SERVER,
      // baseURL: 'http://localhost:8080/api',
    });
    this.axiosInstance.interceptors.response.use(
      function (config) {
        return config;
      },
      (error) => {
        if (error.message === 'Network Error') {
          // window.location = '/504';
        }

        const {
          response: {
            status = null,
            data: { message },
          },
        } = error;
        const originalRequest = error.config;

        switch (status) {
          case 500:
            throw new Error(JSON.stringify(message));
          // return Promise.reject({ response: { data: { errors: { message } } } });
          case 404:
            // window.location = `/404`;
            break;
          case 401:
            return services.authServices.login().then((res) => {
              originalRequest.headers['Authorization'] = `Bearer ${res.id_token}`;
              return axios.request(originalRequest);
            });

          case 403:
            sessionStorage.setItem('REDIRECT', window.location.pathname);
            localStorage.removeItem(`${APP_NAME}_TOKEN`);
            window.location = '/';
            return Promise.reject(error);
          default:
            break;
        }
        return Promise.reject(error);
      }
    );
  }

  getToken = () => localStorage.getItem(`${APP_NAME}_TOKEN`);

  setToken = (token) => {
    localStorage.setItem(`${APP_NAME}_TOKEN`, token);
  };
  removeToken = () => {
    localStorage.removeItem(`${APP_NAME}_TOKEN`);
  };

  get = (url, payload = {}) => {
    const config = Object.assign({ params: payload }, headers(this.getToken()));
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(url, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };

  getDocInfo = async (url, payload = {}) => {
    const config = Object.create({ params: payload });
    let res;
    try {
      res = await this.axiosInstance.get(url, config);
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  post = (url, payload) => {
    const config = Object.assign(headers(this.getToken()), {});
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(url, payload, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };

  postForm = async (url, payload) => {
    const config = Object.create({ params: payload });
    const res = await this.axiosInstance.post(url, payload, config);
    return res;
  };

  postFeedback = async (url, payload) => {
    const config = Object.create({ params: payload });
    const res = await this.axiosInstance.post(url, payload, config);
    return res;
  };

  put = (url, payload) => {
    const config = Object.assign(headers(this.getToken()), {});
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(url, payload, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };
  patch = (url, payload) => {
    const config = Object.assign(headers(this.getToken()), {});
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .patch(url, payload, config)
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };
  delete = (url, payload) => {
    const config = Object.assign(headers(this.getToken()), {});

    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(url, { data: payload, ...config })
        .then((res) => resolve(responseParser(res)))
        .catch((err) => reject(errorParser(err)));
    });
  };
}
