import React from 'react';

export default ({ data }) => {
  return data.contents.map((item) => (
    <div key={item.title} class="mobile-submenu-col">
      <div class="mobile-submenu-title">{item.title}</div>
      <div class="mobile-submenu-items  ">
        <div class="single-item ">
          {item.items.map((element) => (
            <a key={element.text} href={element.link}>
              {element.text}
            </a>
          ))}
        </div>

        {/* <div class="single-item remaining-items ">
          <a href="/technology/sd-wan-solutions/">SD-WAN</a>
          <a href="/technology/speedfusion-bonding-technology/">About SpeedFusion</a>
          <a href="/technology/mobile-bandwidth-bonding/">Cellular Bonding</a>
          <a href="/technology/private-5g/">Private 5G Networks</a>
          <a href="/technology/esim/">eSIM Routers</a>
          <a href="/technology/defense-in-depth/">Peplink Security</a>
          <a href="/enterprise-solutions/intouch/">InTouch Remote Management</a>
        </div> */}
      </div>
    </div>
  ));
};
