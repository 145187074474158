import React from 'react';

export default ({ id, title, content, link, isExpanded, onToggle }) => {
  return (
    <div class={`footer-menu-items menu-toggle-${title}`}>
      <div class="footer-menu-item-box" onClick={() => onToggle(id)}>
        <div class="menu-title">
          <a href={link}>
            <span>{title}</span>
          </a>
        </div>
        <div class={`footer-vector-down-${title} footer-vector-down ${isExpanded ? '' : 'active'}`}>
          <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L9 9L17 1" stroke="white" stroke-width="2" stroke-linecap="round"></path>
          </svg>
        </div>
        <div class={`footer-vector-up-${title} footer-vector-up ${isExpanded ? 'active' : ''}`}>
          <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 10L9 2L1 10" stroke="white" stroke-width="2" stroke-linecap="round"></path>
          </svg>
        </div>
      </div>
      {isExpanded && (
        <div class={`menu-content footer-${title} ${isExpanded ? 'active' : ''}`}>
          <ul>
            {content.map((data) => (
              <div key={data.id}>
                <a href={data.link}>{data.text}</a>
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
