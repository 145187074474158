import React from 'react';

export default ({ data }) => {
  return (
    <>
      {data.contents.map((item) => (
        <div key={item.type} class="mobile-submenu-col">
          {item.type === 'subitem' ? (
            <>
              <div class="mobile-submenu-title"></div>
              <div class="mobile-submenu-items  ">
                <div class="subitem  ">
                  {item.items.map((element) => (
                    <>
                      <div class="sub-title">{element.item}</div>
                      <div class="sub">
                        {element.subitem.map((el, index) => (
                          <>
                            <div class="sub-title-item">
                              <a className={index === 0 ? 'first-link' : ''} href={el.link}>
                                {el.text}
                              </a>
                            </div>
                            {index !== element.subitem.length - 1 && <>|</>}
                          </>
                        ))}
                      </div>
                    </>
                  ))}
                </div>
                <div class="subitem remaining-items "></div>
              </div>
            </>
          ) : (
            <>
              <div class="mobile-submenu-title">{item.title}</div>
              <div class="mobile-submenu-items  ">
                <div class="single-item ">
                  {item.items.map((element) => (
                    <a key={element.text} href={element.link}>
                      {element.text}
                    </a>
                  ))}
                </div>
                <div class="single-item remaining-items "></div>
              </div>
            </>
          )}
        </div>
      ))}
    </>
  );
};
