import React from 'react';
import InvalidURL from './InvalidURL';
import '../styles/components/main.css';

const NoMatch = () => {
  return (
    <>
      <div className="outer-container">
        <InvalidURL />
      </div>
    </>
  );
};

export default NoMatch;
