import React from 'react';

export default ({ data }) => {
  return data.contents.map((item) => (
    <div key={item.title} class="mobile-submenu-col">
      <div class="mobile-submenu-title">{item.title}</div>
      <div class="mobile-submenu-items  ">
        <div class="single-item ">
          {item.items.map((element) => (
            <a key={element.text} href={element.link}>
              {element.text}
            </a>
          ))}
        </div>
      </div>
    </div>
  ));
};
