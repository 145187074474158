import React from 'react';

export default ({ data }) => {
  return (
    <div className="menu-section">
      {data[0].link ? (
        <a href={data[0].link}>
          <p>{data[0].title}</p>
        </a>
      ) : (
        <p>{data[0].title}</p>
      )}
      <div className="menu-item">
        <div class="single-item ">
          {data[0].items.map((item, index) => (
            <a key={index} href={item.link}>
              {item.text}
            </a>
          ))}
        </div>
        <div class="single-item remaining-items "></div>
      </div>
    </div>
  );
};
