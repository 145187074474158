export default class ProductServices {
  constructor(props) {
    this.url = '';
    this.http = props.http;
  }
  getProduct = (productCode) => {
    return this.http.get('/products/details', {
      productCode,
    });
  };
}
