import Data from './data.json';
import DesktopFooter from './DesktopFooter';
import React from 'react';
import TabletMobileFooter from './TabletMobileFooter';

export default () => {
  const [tabletData, setTabletData] = React.useState([]);
  const [expandedId, setExpandedId] = React.useState([]);
  const handleToggle = (id) => {
    setExpandedId((prevItems) => {
      if (prevItems.includes(id)) {
        // If the item is already expanded, remove it from the array
        return prevItems.filter((itemId) => itemId !== id);
      } else {
        // If the item is not expanded, add it to the array
        return [...prevItems, id];
      }
    });
  };

  React.useEffect(() => {
    let tem = [];
    for (let data of Data) {
      tem = [...tem, ...data];
    }
    setTabletData(tem);
  }, []);

  return (
    <>
      <footer id="footer">
        <div class="footer-logo-box">
          <div class="footer-logo"></div>
        </div>
        <div class="footer-upper">
          {Data.map((data, index) => (
            <DesktopFooter key={index} data={data} />
          ))}
        </div>
        <div class="footer-upper-tab">
          {tabletData.length > 0 &&
            tabletData.map((item, index) => (
              <TabletMobileFooter
                key={index}
                id={item.id}
                title={item.title}
                link={item.link}
                content={item.item}
                isExpanded={expandedId.includes(item.id)}
                onToggle={handleToggle}
              />
            ))}
        </div>
        <div class="footer-bottom">
          <div class="copyright">© {new Date().getFullYear()} Peplink All Rights Reserved.</div>
          <div class="footer-bottom-inner">
            <a href="https://www.peplink.com/company/privacy/">
              <span>Privacy Policy</span>
            </a>
            <a href="https://www.peplink.com/sitemap/">
              <span>Site Map</span>
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};
