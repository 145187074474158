import React from 'react';
import Submenu from './Submenu';

export default ({ data, Data, blackHeaderBackground, currentTopic, setCurrentTopic }) => {
  const [timeoutId, setTimeoutId] = React.useState(null);

  const handleMouseEnter = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      setCurrentTopic(data.name);
    }, 300);

    setTimeoutId(newTimeoutId);
  };

  const handleMouseLeave = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setCurrentTopic(null);
  };

  return (
    <li
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`header-li children-icon-display-true menu-item-has-children menu-item ${
        currentTopic === data.name ? 'hovered-menu-item' : ''
      }`}
    >
      <a className={!data.link && 'no-a-link'} href={data.link}>
        <div>{data.name}</div>
        <div className="btn-border"></div>
      </a>
      <input id="655c499b67777" class="open-submenu visually-hidden" type="checkbox" />
      <label for="655c499b67777" class="expand-menu-item">
        <svg viewBox="0 0 100 100" stroke="">
          <path class="vertical" d="M50 0 L 50 100"></path>
          <path d="M0 50 L 100 50"></path>
        </svg>{' '}
      </label>
      <Submenu key={data.name} data={data} name={data.name} Data={Data} blackHeaderBackground={blackHeaderBackground} />
    </li>
  );
};
