import AuthServices from './auth';
import FormServices from './form';
import Http from './http';
import MenuServices from './menu';
import ProductServices from './product';

const http = new Http();

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  authServices: new AuthServices({ http }),
  productServices: new ProductServices({ http }),
  menuServices: new MenuServices(),
  formServices: new FormServices({ http }),
};
